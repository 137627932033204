.p-datepicker-header {
  background-color: #fbeef1;
}

.p-datepicker-title {
  color: #6b1d66;
}

.p-datepicker-next,
.p-datepicker-prev {
  color: #6b1d66;
}

.p-datepicker-today span {
  background-color: white;
  color: #495057;
}

.p-datepicker-calendar thead {
  color: #6b1d66;
}

td[data-p-today="false"][data-pc-section="day"]
  span[data-p-highlight="false"][data-p-disabled="false"][data-pc-section="daylabel"]:hover {
  background-color: #fbeef1;
  color: #6b1d66;
}

.p-yearpicker-year:hover {
  background-color: #fbeef1;
  color: #6b1d66;
}

.p-monthpicker-month:hover {
  background-color: #fbeef1;
  color: #6b1d66;
}

.p-highlight {
  background-color: #f8c7d7;
  color: #6b1d66;
}

#expectedDeliveryDate_panel,
#dateOfBirth_panel,
#dateOfBirthPartner_panel {
  max-width: 400px;
  min-width: 300px !important;
}

#dateOfBirth_panel > div > div > div.p-datepicker-header > div > button.p-datepicker-month.p-link {
  margin-right: 10px;
}

#expectedDeliveryDate_panel > div > div > div.p-datepicker-header > div > button.p-datepicker-month.p-link {
  margin-right: 10px;
}

#dateOfBirthPartner_panel > div > div > div.p-datepicker-header > div > button.p-datepicker-month.p-link {
  margin-right: 10px;
}
