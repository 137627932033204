@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  line-height: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #4d4d4d;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #333;
}

.test {
  background: linear-gradient(1deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}

.react-datepicker-wrapper {
  width: 100%;
}

@layer components {

  .headline-big {
    @apply text-[40px] md:text-[52px] font-bold font-serif text-babymam-900 leading-tight;
  }
  .headline-medium {
    @apply text-[28px] md:text-[36px] font-bold text-babymam-900 leading-tight;
  }

  .headline-small {
    @apply text-[22px] md:text-[28px] font-bold text-babymam-900 leading-tight;
  }

  .headline-sub {
    @apply text-[24px] md:text-[24px] font-sans text-babymam-900 leading-tight;
  }

  {/* Article Styling */}
  .article h1 {
    @apply text-[40px] md:text-[52px] font-bold font-serif text-babymam-900 leading-tight mb-4;
  }
  .article h2 {
    @apply text-[28px] md:text-[36px] font-bold text-babymam-900 leading-tight mb-4;
  }
  .article h3 {
    @apply text-[22px] md:text-[28px] font-bold text-babymam-900 leading-tight mb-4;
  }

  .article section { @apply mb-8; }
  .article p { @apply mb-4; }
  .article ul { @apply list-disc ml-6 mb-4; }
  .article ol { @apply list-decimal ml-6 mb-4; }

}

faq-item p a {
  color: blue;
  text-decoration: underline;
}